import { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'

import {
  FIND_MANY_MEETING,
  FindManyMeetingPayload,
  FindManyMeetingVariables,
} from 'queries/meeting'
import { MeetingTypeProject } from 'types/meeting'

const todayStart = new Date()
todayStart.setHours(0, 0, 0, 0)
const todayEnd = new Date()
todayEnd.setHours(23, 59, 59, 999)
const tomorrowStart = new Date(todayStart)
tomorrowStart.setDate(tomorrowStart.getDate() + 1)
const tomorrowEnd = new Date(todayEnd)
tomorrowEnd.setDate(tomorrowEnd.getDate() + 1)

export const useSelectionCenterManyMeetings = (
  selectionCenterIdentifier?: string,
) => {
  const [
    fetchTodayMeetings,
    {
      data: { meetings: meetingsToday } = { meetings: [] },
      loading: loadingMeetingsToday,
      refetch: refetchMeetingsToday,
    },
  ] = useLazyQuery<FindManyMeetingPayload, FindManyMeetingVariables>(
    FIND_MANY_MEETING,
  )

  const [
    _fetchTomorrowMeetings,
    {
      loading: loadingTomorrowMeetings,
      data: { meetings: meetingsTomorrow } = { meetings: [] },
      refetch: refetchTomorrowMeetings,
      called: isCalledTomorrowMeetings,
    },
  ] = useLazyQuery<FindManyMeetingPayload, FindManyMeetingVariables>(
    FIND_MANY_MEETING,
  )

  useEffect(() => {
    if (!selectionCenterIdentifier) return
    fetchTodayMeetings({
      fetchPolicy: 'cache-and-network',
      variables: {
        orderBy: {
          startTime: { sort: 'asc' },
        },
        where: {
          AND: [
            {
              metadata: {
                path: ['selectionCenter', 'identifier'],
                equals: selectionCenterIdentifier,
              },
            },
            {
              metadata: {
                path: ['type'],
                equals: MeetingTypeProject.SELECTION_CENTER,
              },
            },
          ],
          startTime: {
            gte: todayStart.toISOString(),
            lte: todayEnd.toISOString(),
          },
        },
      },
    })

    isCalledTomorrowMeetings && fetchTomorrowMeetings()
  }, [selectionCenterIdentifier])

  const fetchTomorrowMeetings = () => {
    if (!selectionCenterIdentifier) return
    return _fetchTomorrowMeetings({
      fetchPolicy: 'cache-and-network',
      variables: {
        orderBy: {
          startTime: { sort: 'asc' },
        },
        where: {
          AND: [
            {
              metadata: {
                path: ['selectionCenter', 'identifier'],
                equals: selectionCenterIdentifier,
              },
            },
            {
              metadata: {
                path: ['type'],
                equals: MeetingTypeProject.SELECTION_CENTER,
              },
            },
          ],
          startTime: {
            gte: tomorrowStart.toISOString(),
            lte: tomorrowEnd.toISOString(),
          },
        },
      },
    })
  }

  const refetchAll = () => {
    Promise.all([refetchMeetingsToday(), refetchTomorrowMeetings()])
  }

  return {
    meetingsToday,
    loadingMeetingsToday,
    refetchMeetingsToday,
    loadingTomorrowMeetings,
    meetingsTomorrow,
    fetchTomorrowMeetings,
    refetchTomorrowMeetings,
    refetchAll,
  }
}
